import React from "react"
import Layout from "../template/index"
import SEO from "../utils/seo"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import SiteLink from "../bits/siteLink"

const Title = styled.h5`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
`

const Careers = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "careers.png" }) {
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Career at GECKO" />
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Img fixed={data.file.childImageSharp.fixed} />
        <Title>Fancy working in fast growing tech company ??</Title>
        <p>
          We're always looking for interesting CV's, send yours onto us at
          <SiteLink to="mailto:hello&#64;geckogovernance.com">
            {" "}
            <b>hello&#64;geckogovernance.com</b>
          </SiteLink>
        </p>
      </div>
    </Layout>
  )
}

export default Careers
